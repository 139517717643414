import React from 'react';
import CountUp from 'react-countup';

const Count = () => {
    return(
        <div className="homepage-numbers">
            <div className="countries-numbers">
                <CountUp className='counter' start={0} duration={2.5} end={10} />
                <p>COUNTRIES</p>
            </div>
            <div className="providers-numbers">
                <CountUp className='counter' start={0} duration={2.5} end={254} />
                <p>PROVIDERS</p>
            </div>
            <div className="relationships-numbers">
                <CountUp className='counter' start={0} duration={2.5} end={400} />
                <p>RELATIONSHIPS</p>
            </div>
            <div className="investors-numbers">
                <CountUp className='counter' start={0} duration={2.5} end={145} />
                <p>INVESTORS</p>
            </div>
        </div>
    );
}

export default Count;