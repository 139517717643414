import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "categories", "feed", "providers" ]

  connect() {}

  toggle() {
    this.categoriesTarget.classList.toggle("d-none")
  }

  toggleFeed() {
    this.feedTarget.classList.toggle("d-none")
    this.providersTarget.classList.toggle("d-none")
  }
}