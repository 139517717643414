import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "loadingBar", "scrollElement" ]
        
    initialize() {
      this.hitCounter = 0
      this.switchTracker = true
    }

    connect() {}

    scroll(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)
      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1
        
        this.loadingBarTarget.classList.toggle("d-none")
        let url = `/portal?counter=${this.hitCounter}`
        
        if(this.element.dataset.searchQuery != '[]' && this.element.dataset.searchQuery != '') {   
          url = window.location.href + `&counter=${this.hitCounter}`
        }
        
        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.scrollElementTarget.style.display = "none"
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }
}