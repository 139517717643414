import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "container", "input" ]

    connect() {
       console.log("Companies list!")
    }

    findCompanies(event) {
      fetch(`/companies/list?query=${event.target.value}`, {
        method: 'GET',
        headers: {'Accept': 'text/plain' }
      })
      .then(response => response.text())
      .then((data) => {
        this.containerTarget.innerHTML = data
      })
    }
}